import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { orange } from 'src/styles/colors'
import { PrimaryButtonLinkProps } from './types'
import styled from 'styled-components'

const HoverStyle = styled.a`
  &:hover {
    color: ${orange.extra};
  }
`
const OutlineLinkButton = ({ onClickHandler, dataLayer, href, children }: PrimaryButtonLinkProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <HoverStyle
      className='btn btn--lg text-orange--extra text-none mt-md-2'
      title={dataLayer.section_name}
      href={href}
      target='_blank'
      rel='noreferrer'
      style={{ border: `1px solid ${orange.extra}` }}
      onClick={() => {
        onClickHandler?.()
        sendDatalayerEvent({
          section: dataLayer.section,
          section_name: dataLayer.section_name,
          element_action: 'click button',
          element_name: children,
          redirect_url: href,
          c_page: window.location.href,
        })
      }}
    >
      {children}
    </HoverStyle>
  )
}

export default OutlineLinkButton
