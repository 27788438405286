import React, { useEffect } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import SectionComponent from 'src/components/SectionComponent'
import { WIDTH_XL } from 'src/styles/breakpoints'
import usePageQuery from './pageQuery'

import CTAButton from '../components/CTAButton/_index'
import { BreadCrumbs } from '@interco/inter-ui/components/BreadCrumbs'
import { BreadCrumbsContainer } from './style'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { initTheme, Theme } from '@interco/inter-ui'

const CONTEUDO_TEXTO = {
  h1: 'Carteira Inteligente: gestão especializada de investimentos',
  paragrafo_1: 'Obtenha a melhor gestão de investimentos com especialistas que criam estratégias de acordo com o seu perfil, buscando rentabilidade e segurança. Deixe-nos cuidar de tudo para você, sem se preocupar com a complexidade do mercado financeiro.',
  cta: 'Comece agora!',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_01',
  section_name: CONTEUDO_TEXTO.h1,
}

const HeroCarteiraInteligente = () => {
  const data = usePageQuery()
  const width = useWidth()
  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <>
      <SectionComponent
        id='hero-carteira-inteligente'
        bgTablet='bgHeroCarteiraInteligenteMD'
        bgDesktopLG='bgHeroCarteiraInteligenteLG'
        bgDesktopXL='bgHeroCarteiraInteligenteXL'
        bgDesktopXXL='bgHeroCarteiraInteligenteXXL'
        backgroundSize={width <= WIDTH_XL ? 'contain' : 'cover'}
        backgroundPosition='center bottom'
        pageDataImages={data}
        SectionStyles='py-5 bg-white align-items-end align-items-md-center'
        minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
      >
        <BreadCrumbsContainer className='col-12 p-0 mb-3'>
          <BreadCrumbs
            iconFirst={{ icon: <Home height={24} width={24} color='#6A6E81' />, url: 'https://inter.co/' }}
            configs={{ separator: <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' /> }}
            params={[
                { text: 'Investimentos', url: 'https://inter.co/pra-voce/investimentos/' },
                { text: 'Carteira inteligente' },
            ]}
          />
        </BreadCrumbsContainer>
        <div className='col-12 col-sm-6 p-md-0'>
          <h1 className='font-citrina text-grayscale--500 fs-28 fw-700 lh-33 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-57 mb-3'>{CONTEUDO_TEXTO.h1}</h1>
          <p className='fs-14 text-grayscale--500 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-21 mb-4'>{CONTEUDO_TEXTO.paragrafo_1}</p>
          <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
            {CONTEUDO_TEXTO.cta}
          </CTAButton>
        </div>
      </SectionComponent>
    </>
  )
}

export default HeroCarteiraInteligente
