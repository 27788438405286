import React, { ReactNode } from 'react'

import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import { breakpoints } from 'src/styles/breakpoints'
import ToolTip from 'src/components/ToolTipOnMouseOver/index'

const Container = styled.div`
background-color: #FFF;
border-radius: 8px;
padding: 24px;
border: 1px solid ${grayscale['200']};

@media (min-width: ${breakpoints.md} ) {
  max-width: 311px;
  max-height: 330px;
}

ul {
  list-style: none;
}

li {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: flex-row;
}

.icon {
  background-color: ${grayscale['100']};
  height: 38px;
  width: 38px;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  line-height: 17px;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  p {
    margin: 0;
    color: ${grayscale['500']};
  }
}
`
export type IconListItem = {
    id: string;
    iconName: string;
    iconColor: string;
    title: string;
    content: string;
    haveTooltip?: boolean;
}

const textTooltip = 'Os números apresentados são rentabilidade bruta e estimados, não havendo responsabilidade da gestora em replicá-los e podem sofrer alteração dependendo das condições de mercado, cenário macroeconômico e disponibilidade dos ativos."'

const CardIconList = ({ list }: {list: IconListItem[]}) => {
    const Tooltip = <ToolTip text={textTooltip} iconTooltip='help' sizeIcon='MD' colorIcon='#FF7A00' />

    return list?.length ? (
      <ul>
        {list.map((item: IconListItem) => (
          <li key={item.id} className='mb-3'>
            <div className='icon'>
              <OrangeIcon icon={item.iconName} color={item.iconColor} size='MD' className='bg-grayscale--100' />
            </div>
            <div className='content-container ml-3'>
              <div className='d-flex flex-wrap align-items-center'>
                <p className='fs-14 lh-16 pr-2'>{item.title}</p>
                {item.haveTooltip && Tooltip}
              </div>
              <p className='font-citrina fs-20 lh-24 fw-700'>{item.content}</p>
            </div>
          </li>
        ))}
      </ul>
    ) : <></>
}

const CardCarteiraInteligente = ({ title, list, children }: {title: string; list: IconListItem[]; children?: ReactNode}) => {
    return (
      <Container>
        <h3 className='font-sora fw-600 text-grayscale--500 fs-20 lh-25'>{title}</h3>
        <div>
          {children}
        </div>
        <CardIconList list={list} />
      </Container>
    )
}

export default CardCarteiraInteligente
