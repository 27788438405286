
import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CarouselContainer = styled.div`
  li {
    max-width: 300px !important;
  }
`

export const CardContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 24px; 
  }

  @media (min-width: ${breakpoints.md}) {
    margin-right: 24px;
    max-width: 311px;
    max-height: 330px;
    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 40px; 
    }
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
