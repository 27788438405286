import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CardContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #B6B7BB;
  max-height: 259px;
  max-width: 264px;
  padding: 36px;
  height: 100%;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 95%;
  }
  svg {
    width: 22px;
    height: 22px;
  }
`
