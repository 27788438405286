import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import { RiskProfileCardT } from '../components/RiskProfileCard/type'
import RiskProfileCard from '../components/RiskProfileCard/_index'
import { WIDTH_SM, WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { ButtonContainer, CardContainer, CarouselContainer } from './style'
import CTAButton from '../components/CTAButton/_index'

const CONTEUDO_TEXTO = {
  h2: 'Como montar uma Carteira Inteligente?',
  paragrafo_1: 'Com base no seu perfil de investidor, a Carteira Inteligente distribuirá de forma inteligente os investimentos, visando ampliar e diversificar seu patrimônio financeiro para obter os melhores resultados, totalizando 6 variações de carteira.',
  cta: 'Quero saber mais',
}

const CONTEUDO_CARDS: RiskProfileCardT[] = [
  {
    cardTitle: 'Conservador',
    id: 'card_conservador',
    risk: 1,
    list: [
      { id: 'dobra_2_conservador_1', title: 'Número de Fundos/Ativos:', content: '15 a 25', iconColor: '#6A6C72', iconName: 'handbag' },
      { id: 'dobra_2_conservador_2', title: 'Rentabilidade-ativos:', content: 'CDI + 1% ao ano', iconColor: '#6A6C72', iconName: 'investments', haveTooltip: true },
      { id: 'dobra_2_conservador_3', title: 'Volatilidade:', content: '0,1% - 2%', iconColor: '#6A6C72', iconName: 'shuffle' },
    ],
  },
  {
    cardTitle: 'Moderado',
    id: 'card_moderado',
    risk: 2,
    list: [
      { id: 'dobra_2_moderado_1', title: 'Número de Fundos/Ativos:', content: '40 a 60', iconColor: '#6A6C72', iconName: 'handbag' },
      { id: 'dobra_2_moderado_2', title: 'Rentabilidade-ativos:', content: 'CDI + 2% ao ano', iconColor: '#6A6C72', iconName: 'investments', haveTooltip: true },
      { id: 'dobra_2_moderado_3', title: 'Volatilidade:', content: '2% - 5%', iconColor: '#6A6C72', iconName: 'shuffle' },
    ],
  },
  {
    cardTitle: 'Arrojado',
    id: 'card_arrojado',
    risk: 3,
    list: [
      { id: 'dobra_2_arrojado_1', title: 'Número de Fundos/Ativos:', content: '40 a 60', iconColor: '#6A6C72', iconName: 'handbag' },
      { id: 'dobra_2_arrojado_2', title: 'Rentabilidade-ativos:', content: 'CDI + 5% ao ano', iconColor: '#6A6C72', iconName: 'investments', haveTooltip: true },
      { id: 'dobra_2_arrojado_3', title: 'Volatilidade:', content: '5% - 15%', iconColor: '#6A6C72', iconName: 'shuffle' },
    ],
  },
]

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_02',
  section_name: CONTEUDO_TEXTO.h2,
}

const ComoMontarCarteira = () => {
  const width = useWidth()
  const showCarrousel = width >= WIDTH_SM && width <= WIDTH_MD
    return (
      <>
        <SectionComponent
          id='como-montar-carteira'
          SectionStyles='py-5 bg-gray-brand align-items-end align-items-md-center col-12'
          minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
        >
          <h2 className='font-citrina text-grayscale--500 fw-700 text-center fs-28 lh-33 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-57 mb-3 w-100'>{CONTEUDO_TEXTO.h2}</h2>
          <p className='text-center text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-21 mb-sm-4 mb-md-0 mb-lg-4'>{CONTEUDO_TEXTO.paragrafo_1}</p>
          {showCarrousel &&
            <CarouselContainer className='col-12'>
              <DefaultCarousel
                md={{ items: 2, partialVisibilityGutter: 30, slidesToSlide: 1 }}
                customDotColor='#FF7A00'
                itemClass='mr-md-4 '
                notShowArrows
                notShowDots
              >
                {CONTEUDO_CARDS.map((card: RiskProfileCardT) => (
                  <CardContainer key={card.id}>
                    <RiskProfileCard card={card} />
                  </CardContainer>
                ))}
              </DefaultCarousel>
            </CarouselContainer>
              }

          {!showCarrousel &&
            <div className='d-flex flex-wrap flex-lg-nowrap justify-content-center w-100'>
              {CONTEUDO_CARDS.map((card: RiskProfileCardT) => (
                <CardContainer key={card.id}>
                  <RiskProfileCard card={card} />
                </CardContainer>
                ))}
            </div>
            }
          <ButtonContainer>
            <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
              {CONTEUDO_TEXTO.cta}
            </CTAButton>
          </ButtonContainer>
        </SectionComponent>
      </>
    )
}

export default ComoMontarCarteira
