import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'

import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import dataJson from './assets/data.json'

import { CategoryText, SubCategoryText, CategoriesDiv, BlogCard } from './style'
import { WIDTH_SM } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import OutlineLinkButton from '../components/OutlineLinkButton/_index'

type BlogCardT = {
  id: string;
  category: string;
  subCategory?: string;
  title: string;
  description: string;
  date: string;
  link: string;
}

const CONTEUDO_TEXTO = {
  h2: 'Os melhores conteúdos sobre investimentos',
  paragrafo_1: 'Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.',
}

const AcompanheOBlog = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const blogCard: BlogCardT[] = dataJson
  const width = useWidth()

  return (
    <SectionComponent
      id='acompanhe-o-blog'
      SectionStyles='py-4 bg-gray-brand'
      minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
    >
      <div className='col-12 text-center'>
        <h2 className='font-citrina text-grayscale--500 fw-700 fs-28 lh-33 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-md-2 px-lg-5'>
          {CONTEUDO_TEXTO.h2}
        </h2>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 mb-md-4'>
          {CONTEUDO_TEXTO.paragrafo_1}
        </p>
      </div>

      {blogCard.map((card: BlogCardT) => (
        <div className='col-12 col-md-4' key={card.id}>
          <BlogCard
            href={card.link}
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_09',
                section_name: CONTEUDO_TEXTO.h2,
                element_action: 'click button',
                element_name: card.title,
                redirect_url: card.link,
              })
            }}
          >
            <div key={card.id}>
              <CategoriesDiv className='d-flex'>
                <CategoryText className='font-sora lh-15 fw-700 text-white mr-3' dangerouslySetInnerHTML={{ __html: card.category }} />
                {card.subCategory && <SubCategoryText className='font-sora fs-12 lh-15 fw-700 text-grayscale--500' dangerouslySetInnerHTML={{ __html: card.subCategory }} />}
              </CategoriesDiv>
              <div>
                <h3 className='font-sora fs-20 lh-25 fw-600 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
                <h6 className='fs-12 lh-15 fw-700 text-grayscale--300 mb-0' dangerouslySetInnerHTML={{ __html: card.date }} />
              </div>
              <div className='d-none d-lg-block'>
                <p className='fs-16 lh-20 text-grayscale--500 mb-0 mt-lg-3' dangerouslySetInnerHTML={{ __html: card.description }} />
                <div className='mt-2'>
                  <span
                    title='Leia mais'
                    className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block'
                  >
                    Leia mais
                    <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                  </span>
                </div>
              </div>
            </div>
          </BlogCard>
        </div>
      ))}
      {width > WIDTH_SM && (
        <div className='col-12 d-flex justify-content-center'>
          <OutlineLinkButton
            href='https://blog.inter.co/para-simplificar-a-vida/investir/'
            dataLayer={{ section_name: CONTEUDO_TEXTO.h2, section: 'dobra_09' }}
          >
            Acessar mais artigos
          </OutlineLinkButton>
        </div>
      )
      }
    </SectionComponent>
  )
}

export default AcompanheOBlog
