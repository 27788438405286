import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { PrimaryButtonProps } from './types'

const PrimaryButton = ({ onClickHandler, dataLayer, children }: PrimaryButtonProps) => {
    const [ sendDatalayerEvent ] = useDataLayer()
    return (
      <button
        className='btn btn--lg bg-orange--extra text-white text-none'
        title={dataLayer.section_name}
        onClick={() => {
          onClickHandler?.()
          sendDatalayerEvent({
              section: dataLayer.section,
              section_name: dataLayer.section_name,
              element_action: 'click button',
              element_name: children,
          })
        }}
      >
        {children}
      </button>
    )
}

export default PrimaryButton
