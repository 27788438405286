import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import { CardList } from './style'
import CTAButton from '../components/CTAButton/_index'

const CONTEUDO_TEXTO = {
  h2: 'Afinal, por que investir em uma carteira inteligente?',
  paragrafo_1: 'Descubra as diferenças nos riscos entre investir por conta própria e contar com um especialista ao seu lado.',
  cta: 'Comece agora!',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_03',
  section_name: CONTEUDO_TEXTO.h2,
}

type ListItemT = {
  id: string;
  text: string;
  iconName: string;
  iconColor: string;
}

type CardT = {
  id: string;
  title: string;
  list: ListItemT[];
}

const CONTEUDO_CARDS = [
  {
    title: 'Benefícios de investir com um especialista',
    id: 'card_beneficios',
    list: [
      { id: 'card_beneficios_item_1', text: 'Gestão ativa do patrimônio', iconName: 'check-circle', iconColor: '#00AA4F' },
      { id: 'card_beneficios_item_2', text: 'Alocação definida por um time de analistas', iconName: 'check-circle', iconColor: '#00AA4F' },
      { id: 'card_beneficios_item_3', text: 'Alinhamento de interesses', iconName: 'check-circle', iconColor: '#00AA4F' },
      { id: 'card_beneficios_item_4', text: 'Tomada de decisão com base no cenário macro', iconName: 'check-circle', iconColor: '#00AA4F' },
      { id: 'card_beneficios_item_5', text: 'Resultados consistentes no longo prazo', iconName: 'check-circle', iconColor: '#00AA4F' },
      { id: 'card_beneficios_item_6', text: 'Controle de riscos e monitoramento constante', iconName: 'check-circle', iconColor: '#00AA4F' },
      { id: 'card_beneficios_item_7', text: 'Taxas diferenciadas', iconName: 'check-circle', iconColor: '#00AA4F' },
    ],
  },
  {
    title: 'Riscos de investir sozinho',
    id: 'card_riscos',
    list: [
      { id: 'card_riscos_item_1', text: 'Pouco conhecimento financeiro', iconName: 'canceled', iconColor: '#F56A50' },
      { id: 'card_riscos_item_2', text: 'Vieses na decisão', iconName: 'canceled', iconColor: '#F56A50' },
      { id: 'card_riscos_item_3', text: 'Falta de tempo', iconName: 'canceled', iconColor: '#F56A50' },
      { id: 'card_riscos_item_5', text: 'Perda de oportunidades por falta de timing', iconName: 'canceled', iconColor: '#F56A50' },
      { id: 'card_riscos_item_6', text: 'Dificuldade em tomar decisões de investimento', iconName: 'canceled', iconColor: '#F56A50' },
      { id: 'card_riscos_item_7', text: 'Receio de perder dinheiro', iconName: 'canceled', iconColor: '#F56A50' },
      { id: 'card_riscos_item_8', text: 'Falta de segurança em plataformas', iconName: 'canceled', iconColor: '#F56A50' },
    ],
  },
]

const PorqueInvestirCarteiraInteligente = () => {
  return (
    <SectionComponent
      id='porque-investir-carteira-inteligente'
      SectionStyles='py-5 align-items-md-center col-12'
      minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
    >
      <h2 className='text-center font-citrina text-grayscale--500 fs-28 lh-33 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-57 mb-3 w-100'>{CONTEUDO_TEXTO.h2}</h2>
      <p className='text-center text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-21 mb-4 w-100'>{CONTEUDO_TEXTO.paragrafo_1}</p>
      {CONTEUDO_CARDS.map((card: CardT) => (
        <div className='col-md-6' key={card.id}>
          <CardList>
            <h3 className='font-sora fw-600 fs-16 lh-20 text-grayscale--500 mb-3'>{card.title}</h3>
            <ul>
              {card.list.map((listItem: ListItemT) => (
                <li key={listItem.id}>
                  <div className='mr-2'>
                    <OrangeIcon size='MD' color={listItem.iconColor} icon={listItem.iconName} />
                  </div>
                  <div className='fs-14 fw-400 lh-16 text-grayscale--500'>
                    {listItem.text}
                  </div>
                </li>
              ))}
            </ul>
          </CardList>
        </div>
      ))}
      <div className='col-12 d-flex justify-content-center'>
        <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
          {CONTEUDO_TEXTO.cta}
        </CTAButton>
      </div>
    </SectionComponent>
  )
}

export default PorqueInvestirCarteiraInteligente
