import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { PrimaryButtonLinkProps } from './types'

const PrimaryLinkButton = ({ onClickHandler, dataLayer, href, children }: PrimaryButtonLinkProps) => {
    const [ sendDatalayerEvent ] = useDataLayer()
    return (
      <a
        className='btn btn--lg bg-orange--extra text-white text-none mt-md-2'
        title={dataLayer.section_name}
        href={href}
        onClick={() => {
          onClickHandler?.()
          sendDatalayerEvent({
              section: dataLayer.section,
              section_name: dataLayer.section_name,
              element_action: 'click button',
              element_name: children,
              redirect_url: href,
              c_page: window.location.href,
          })
        }}
      >
        {children}
      </a>
    )
}

export default PrimaryLinkButton
