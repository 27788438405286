import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const CardList = styled.div`
  background-color: #FFF;
  border-radius: 8px;
  padding: 24px;
  padding-bottom: 14px;
  border: 1px solid ${grayscale['200']};
  box-shadow: 0px 8px 16px 2px ${grayscale['500']}14;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 456px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    max-width: 696px;
  }

  @media (min-width: ${breakpoints.md}) {
    h3 {
      min-height: 41px;
      display: flex;
      flex-wrap: wrap;
      align-items: end;
    }
  }

  &:last-of-type {
    margin-bottom: 24px;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 10px 0;
    margin: 0;
    display: flex;
    flex-direction: flex-row;
    align-items: center;
    width: 100%;
    &:not(:last-of-type) {
      border-bottom: 1px solid ${grayscale['200']};
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`
