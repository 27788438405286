import React from 'react'
import SectionComponent from 'src/components/SectionComponent'

import grafismo1 from '../../assets/images/grafismo-ativos-gestao.png'
import grafismo2 from '../../assets/images/grafismo-cotistas.png'
import grafismo3 from '../../assets/images/grafismo-carteiras-inteligentes.png'
import { ContainerBoxes, ContainerGraphism, ContainerGrid } from './style'

type GrafismoT = {
  id: string;
  primary: string;
  text: string;
  background: string;
}

const CONTEUDO_GRAFISMO = [
  { id: 'dobra_06_grafismo_01', primary: 'R$ 9,2 Bi', text: 'de ativos sob gestão', background: grafismo1 },
  { id: 'dobra_06_grafismo_02', primary: '+460 mil', text: 'Cotistas', background: grafismo2 },
  { id: 'dobra_06_grafismo_03', primary: '+690', text: 'Carteiras inteligente', background: grafismo3 },
]

type BoxT = {
  id: string;
  primary: string;
  text: string;
}

const CONTEUDO_BOXES = [
  { id: 'dobra_06_box_01', primary: 'R$ 1,2 Bi', text: 'Multimercado' },
  { id: 'dobra_06_box_03', primary: 'R$ 1,6 Bi', text: 'de Crédito Privado' },
  { id: 'dobra_06_box_05', primary: 'R$ 5,4 Bi', text: 'de Liquidez' },
  { id: 'dobra_06_box_02', primary: 'R$ 48 Mi', text: 'Global' },
  { id: 'dobra_06_box_04', primary: 'R$ 665 Mi', text: 'Imobiliário' },
  { id: 'dobra_06_box_06', primary: 'R$ 373 Mi', text: 'Ações' },
]

const EstatisticasCarteiraInteligente = () => {
  return (
    <SectionComponent
      id='conheca-inter-asset'
      SectionStyles='py-5 bg-gray-brand align-items-end align-items-md-center col-12'
      RowStyles='justify-content-end'
      minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
    >
      {CONTEUDO_GRAFISMO.map((item: GrafismoT) => (
        <div className='col-12 col-md-4 mb-3' key={item.id}>
          <ContainerGraphism background={item.background}>
            <span className='font-citrina fs-48 lh-57 fw-700 fs-md-38 lh-md-44 text-sand'>{item.primary}</span>
            <span className='font-citrina fs-20 fw-700 lh-24 fs-md-16 lh-md-18 text-sand'>{item.text}</span>
          </ContainerGraphism>
        </div>
      ))}

      <ContainerGrid>
        {CONTEUDO_BOXES.map((item: BoxT) => (
          <ContainerBoxes key={item.id}>
            <strong>{item.primary}</strong>
            <span>{item.text}</span>
          </ContainerBoxes>
      ))}
      </ContainerGrid>
      <div className='d-flex justify-content-end'>
        <p className='fs-14 lh-16 fw-400 pt-4' style={{ color: '#72370E' }}>*Dados referentes a 29/03/2024</p>
      </div>
    </SectionComponent>
  )
}

export default EstatisticasCarteiraInteligente
