import { useStaticQuery, graphql } from 'gatsby'

const usePageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bgHeroCarteiraInteligenteMD: imageSharp(fluid: {originalName: { regex: "/bg-hero-carteira-inteligente-md/" }}) {
        fluid(maxHeight: 427, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroCarteiraInteligenteLG: imageSharp(fluid: {originalName: { regex: "/bg-hero-carteira-inteligente-lg/" }}) {
        fluid(maxHeight: 427, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroCarteiraInteligenteXL: imageSharp(fluid: {originalName: { regex: "/bg-hero-carteira-inteligente-lg/" }}) {
        fluid(maxHeight: 571, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgHeroCarteiraInteligenteXXL: imageSharp(fluid: {originalName: { regex: "/bg-hero-carteira-inteligente-lg/" }}) {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default usePageQuery
