import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const ContainerCarousel = styled.div`
.react-multi-carousel-track {
  padding-top: 20px;
  padding-bottom: 40px;
  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 30px;
  }
} 

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}
`
