
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'
import { orange } from 'src/styles/newColors'
import { breakpoints } from 'src/styles/breakpoints'

export const ContainerBoxes = styled.div`
  border: 1px solid ${grayscale['200']};
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 0;
  text-align: center;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  @media (min-width: ${breakpoints.md}) {
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  strong {
    color: ${orange.dark};
    padding-right: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
  span {
    color: ${orange.dark};
    font-size: 14px;
  }
`

export const ContainerGraphism = styled.div<{background: string}>`
  background: url(${(props: {background: string}) => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 180px;
    justify-content: center;
    background-position: bottom;
  }
`

export const ContainerGrid = styled.div`
  width: 100%;
  padding: 0 24px;
  @media (min-width: ${breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    padding: 0;
  }
`
