
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const BreadCrumbsContainer = styled.div`
  overflow-x: auto;
  text-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  div[role=navigation] {
    margin: 0;
  }
  @media (min-width: ${breakpoints.md}) {
    div:first-of-type {
      display: flex;
      align-items: center;
      margin-right: 4px;
    }
    div:not(:first-of-type) {
      margin-right: 4px;
      margin-left: 8px;
    }
    a {
      color: ${grayscale[400]};
      font-size: 14px;
    }
  }
`
