import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import Img from 'gatsby-image'
import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import usePageQuery from './pageQuery'
import IntercoIcons from 'src/components/IntercoIcons'
import CTAButton from '../components/CTAButton/_index'

import { VideoContainer, ContainerLista } from './style'

const CONTEUDO_TEXTO = {
  h2: 'Aproveite os benefícios e insights valiosos sobre sua performance financeira de maneira simples',
  paragrafo_1: 'Obtenha uma carteira automatizada com estratégias atualizadas para impulsionar seus investimentos.',
  cta: 'Invista agora',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_04',
  section_name: CONTEUDO_TEXTO.h2,
}

type ListaCarteiraT = {
  id: string;
  iconName: string;
  iconColor: string;
  text: string;
}

const CONTEUDO_LISTA = [
  { id: 'dobra_04_lista_aportes', iconName: 'money-entry', iconColor: '#6A6C72', text: 'Aporte inicial a partir de R$50 mil' },
  { id: 'dobra_04_lista_carteira', iconName: 'wallet', iconColor: '#6A6C72', text: 'Alocações conforme perfil do investidor' },
  { id: 'dobra_04_lista_relatorios', iconName: 'receipt', iconColor: '#6A6C72', text: 'Relatórios simplificados e assertivos' },
  { id: 'dobra_04_lista_eficiencia', iconName: 'salary-portability', iconColor: '#6A6C72', text: 'Maior eficiência tributária' },
  { id: 'dobra_04_lista_monitoramento', iconName: 'boleto-analysis', iconColor: '#6A6C72', text: 'Monitoramento constante do portfólio' },
  { id: 'dobra_04_lista_taxa', iconName: 'no-taxes', iconColor: '#6A6C72', text: 'Sem taxa de performance' },
]

const AproveiteBeneficiosCarteiraInteligente = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  return (
    <SectionComponent
      id='aproveite-beneficios-carteira-inteligente'
      SectionStyles='py-5 bg-gray-brand align-items-end align-items-md-center col-12'
      minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
    >
      <h2 className='text-center font-citrina fw-700 text-grayscale--500 fs-28 lh-33 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-57 mb-3 w-100'>{CONTEUDO_TEXTO.h2}</h2>
      <p className='text-center text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-21 mb-4 w-100'>{CONTEUDO_TEXTO.paragrafo_1}</p>
      <div className='col-12 col-md-6 mb-3'>
        <VideoContainer className='cursor-pointer'>
          <OpenVideo
            link='https://www.youtube.com/embed/po3budnSAyc?si=8Xej5B1nPlI90oIy'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_04',
                element_action: 'click video',
                section_name: CONTEUDO_TEXTO.h2,
                element_name: 'Assistir Vídeo',
                redirect_url: 'https://www.youtube.com/watch?v=po3budnSAyc',
              })
            }}
          >
            <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/banner-aproveite-os-beneficios/image.webp' alt='Thumb do video com o texto: Por que controlar suas finanças?' className='h-100 w-100' />
          </OpenVideo>
        </VideoContainer>
      </div>
      <div className='col-12 col-md-6 d-lg-flex flex-lg-column align-items-lg-center'>
        <ContainerLista>
          <ul>
            {CONTEUDO_LISTA.map((listItem: ListaCarteiraT) => (
              <li key={listItem.id}>
                <IntercoIcons size='MD' color={listItem.iconColor} icon={listItem.iconName} />
                <span className='fs-14 lh-17 fw-700 text-grayscale--500'>{listItem.text}</span>
              </li>
            ))}
          </ul>
        </ContainerLista>
        <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
          {CONTEUDO_TEXTO.cta}
        </CTAButton>
      </div>
    </SectionComponent>
  )
}

export default AproveiteBeneficiosCarteiraInteligente
