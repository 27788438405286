import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const BlogCard = styled.a`
  cursor: pointer;
  display: block;
  margin-bottom: 24px;
  background-color: #fff;
  padding: 18px 24px;
  border-radius: 16px 16px 0px 0px;

  @media (min-width: ${breakpoints.md}) {
    width: 216px;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 296px;
    min-height: 280px;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 360px;
  } 
  @media (min-width: ${breakpoints.xxl}) {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  } 
`

export const CategoryText = styled.span`
  background-color: ${orange.extra};
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  font-size: 12px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 8px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 12px;
  }
`

export const SubCategoryText = styled.span`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  font-size: 12px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 8px;
  }
`

export const CategoriesDiv = styled.div`
  margin-bottom: 20px;
`
