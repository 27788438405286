import React from 'react'
import IntercoIcons from 'src/components/IntercoIcons'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_SM } from 'src/styles/breakpoints'
import { CardContainer } from './style'

import CTAButton from '../components/CTAButton/_index'

const CONTEUDO_TEXTO = {
  h2: 'Conheça a Inter Asset, 20 anos de experiência em gestão de patrimônio',
  paragrafo_1: 'Nossa equipe é especializada em ações, crédito, fundos e investimentos imobiliários.',
  cta: 'Quero conhecer',
}

type CardT = {
  id: string;
  iconColor: string;
  iconName: string;
  text: string;
}

const CONTEUDO_CARDS = [
  { iconName: 'note-detail', iconColor: '#161616', id: 'dobra_05_proximidade', text: 'Proximidade e foco no cliente (Lives mensais exclusivas, relatório de rentabilidade consolidada, etc).' },
  { iconName: 'boleto-analysis', iconColor: '#161616', id: 'dobra_05_analise', text: 'Análise e alocação em fundos de investimento de toda a indústria, privilegiando os melhores gestores e produtos disponíveis.' },
  { iconName: 'no-taxes', iconColor: '#161616', id: 'dobra_05_sem_taxas', text: 'Sem taxas escondidas: Repassamos para o cliente todo tipo de rebate ou comissionamento dos fundos investidos.' },
  { iconName: 'wallet', iconColor: '#161616', id: 'dobra_05_relatorios', text: 'Relatórios sistematizados com a carteira e resultados consolidados.' },
  { iconName: 'profitability', iconColor: '#161616', id: 'dobra_05_atualizacao', text: 'Atualização e monitoramento constante das carteiras.' },
]

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_05',
  section_name: CONTEUDO_TEXTO.h2,
}

const ConhecaInterAsset = () => {
  const width = useWidth()
  const isSmallDevice = width <= WIDTH_SM

    return (
      <>
        <SectionComponent
          id='conheca-inter-asset'
          SectionStyles='py-5 align-items-end align-items-md-center col-12'
          minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
        >
          <div className='col-md-6'>
            <h2 className='text-grayscale--500 fw-700 font-citrina fs-28 lh-33 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-57 mb-3 w-100'>{CONTEUDO_TEXTO.h2}</h2>
            <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-21 mb-0 mb-md-4'>{CONTEUDO_TEXTO.paragrafo_1}</p>
            {!isSmallDevice && (
              <div className='w-100'>
                <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
                  {CONTEUDO_TEXTO.cta}
                </CTAButton>
              </div>
            )}
          </div>
          <div className='col-12 col-md-6 mb-4'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
              md={{ items: 1, partialVisibilityGutter: 50 }}
              lg={{ items: 1, partialVisibilityGutter: 150 }}
              xl={{ items: 2, partialVisibilityGutter: 20 }}
              customDotColor='#FF7A00'
            >
              {CONTEUDO_CARDS.map((card: CardT) => (
                <CardContainer key={card.id}>
                  <IntercoIcons size='MD' color={card.iconColor} icon={card.iconName} />
                  <p className='text-left text-grayscale--500 fs-18 fw-400 lh-22 mt-1'>{card.text}</p>
                </CardContainer>
                ))}
            </DefaultCarousel>
          </div>
          {isSmallDevice && (
            <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
              {CONTEUDO_TEXTO.cta}
            </CTAButton>
          )}
        </SectionComponent>
      </>
    )
}

export default ConhecaInterAsset
