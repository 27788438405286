import React from 'react'
import RiskMeter from 'src/components/RiskMeter'
import CardCarteiraInteligente from '../CardCarteiraInteligente/_index'
import { RiskProfileCardT } from './type'

const RiskProfileCard = ({ card }: {card: RiskProfileCardT}) => {
  return (
    <CardCarteiraInteligente title={card.cardTitle} list={card.list}>
      <div className='bg-grayscale--100 p-3 mb-3 w-100 d-flex align-items-center' style={{ borderRadius: '8px', height: '57px' }}>
        <RiskMeter markerPosition={card.risk} />
      </div>
    </CardCarteiraInteligente>
  )
}

export default RiskProfileCard
