import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import ImageWebp from 'src/components/ImageWebp'

import comoInvestir from '../../assets/images/como-investir.png'
import gestaoRecursos from '../../assets/images/gestao-recursos.png'
import gestaoPatrimonio from '../../assets/images/gestao-patrimonio.png'
import { ContainerLogos } from './style'

type TextoT = {
  id: string;
  text: string;
}

const CONTEUDO_TEXTO = [
    { id: 'dobra_10_disclaimer_1', text: 'Pretende-se com este material apenas divulgar informações e dar transparência à gestão executada pela Inter Asset Gestão de Recursos Ltda.' },
    { id: 'dobra_10_disclaimer_2', text: 'As decisões de investimento devem ser tomadas após compreensão dos riscos envolvidos, análise dos objetivos pessoais e leitura cuidadosa dos documentos relacionados ao investimento desejado.' },
    { id: 'dobra_10_disclaimer_3', text: 'As simulações de composição da carteira e as projeções macroeconômicas constituem modelos matemáticos e estatísticos que utilizam dados históricos e suposições para tentar prever o comportamento futuro da economia e, consequentemente, os possíveis cenários que eventualmente afetem a carteira.' },
    { id: 'dobra_10_disclaimer_4', text: 'Não há garantia de que quaisquer dessas estimativas ou projeções serão alcançadas. Rentabilidade obtida no passado não representa garantia de resultados futuros.' },
    { id: 'dobra_10_disclaimer_5', text: 'A rentabilidade divulgada não é liquida de impostos. Os indicadores econômicos são meras referências econômicas, e não metas ou parâmetros de performance.' },
]

const Disclaimer = () => {
  return (
    <SectionComponent
      id='disclaimer'
      SectionStyles='py-5 align-items-md-center col-12'
      minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
    >
      <ContainerLogos className='mb-4'>
        <ImageWebp className='p-1' pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-ambima-gestao-de-recursos/image.webp' altDescription='Autorregulação ANBIMA - Gestão de Recursos' arrayNumbers={[ 75, 108, 108, 108 ]} arrayNumbersHeight={[ 56, 81, 81, 81 ]} />
        <ImageWebp className='p-1' pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-ambima-gestao-de-patrimonio/image.webp' altDescription='Autorregulação ANBIMA - Gestão de Patrimônio' arrayNumbers={[ 75, 108, 108, 108 ]} arrayNumbersHeight={[ 56, 81, 81, 81 ]} />
        <ImageWebp className='p-2' pathSrc={comoInvestir} altDescription='Como Investir' arrayNumbers={[ 96, 130, 130, 130 ]} arrayNumbersHeight={[ 52, 68, 68, 68 ]} />
      </ContainerLogos>
      <div className='col-12'>
        {CONTEUDO_TEXTO.map((item: TextoT) => (
          <p className='fs-13 fw-400 text-grayscale--500 text-center lh-14' key={item.id}>{item.text}</p>
        ))}
      </div>

    </SectionComponent>
  )
}

export default Disclaimer
