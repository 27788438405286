import React, { ReactNode } from 'react'
import './RiskMeter.scss'

type RiskMeterProps = {
  markerPosition: number;
  leftLabel?: string;
  rightLabel?: string;
  showLabels?: boolean;
}

const RiskMeter = ({ markerPosition = 1, leftLabel, rightLabel, showLabels = false }: RiskMeterProps) => {
  if (markerPosition > 3 && markerPosition < 1) throw new Error('Marker position must be 1, 2 or 3')
  const markerProgressPosition = [ 11.05, 10.2 * 4.5, 10 * 8 ]
  const progress: number = markerProgressPosition[markerPosition - 1]

  const descriptionHeader: ReactNode =
    <div className='description'>
      <span className='risk-description risk-description--left'>{leftLabel}</span>
      <span className='risk-description risk-description--right'>{rightLabel}</span>
    </div>

  const markerCircle: ReactNode = <div className='marker' style={{ marginLeft: progress + '%' }} />

  return (
    <div className='RiskMeter'>
      {showLabels && descriptionHeader}
      <div className='risk-meter'>
        <div className='bar bar--0' />
        <div className='bar bar--1' />
        <div className='bar bar--2' />
      </div>
      {markerPosition && markerCircle}
    </div>
  )
}

export default RiskMeter
