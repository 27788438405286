import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContainerLogos = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    img {
      margin-right: 40px;
    }
  }
`
