import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const VideoContainer = styled.div`
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 320px;
      height: 280px;
    }

    @media ${device.desktopLG} {
      width: 453px;
      height: 304px;
    }
  }
`

export const ContainerLista = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ul {
    list-style: none;
  }

  li svg {
    width: 22px;
    height: 22px;
  }
  li span {
    margin-left: 8px;
  }
  li:not(:last-of-type) {
    margin-bottom: 14px;
  }
`
